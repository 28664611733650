// Generated by Framer (70e732d)

import { addFonts, cx, CycleVariantState, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const cycleOrder = ["WieQfSc3K"];

const variantClassNames = {WieQfSc3K: "framer-v-1a32fzm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "WieQfSc3K", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "WieQfSc3K", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-vOtMe", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1a32fzm", className)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"WieQfSc3K"} ref={ref} style={{backgroundColor: "var(--token-91046826-b395-475a-a623-78be4df1fbd2, rgb(239, 239, 239))", ...style}} transition={transition}/>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-vOtMe [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vOtMe .framer-1att8m9 { display: block; }", ".framer-vOtMe .framer-1a32fzm { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; min-height: 76px; overflow: visible; padding: 32px 120px 32px 120px; position: relative; width: 1400px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vOtMe .framer-1a32fzm { gap: 0px; } .framer-vOtMe .framer-1a32fzm > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-vOtMe .framer-1a32fzm > :first-child { margin-left: 0px; } .framer-vOtMe .framer-1a32fzm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 76
 * @framerIntrinsicWidth 1400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 */
const FramerDXBkxdVH8: React.ComponentType<Props> = withCSS(Component, css, "framer-vOtMe") as typeof Component;
export default FramerDXBkxdVH8;

FramerDXBkxdVH8.displayName = "Site Nav";

FramerDXBkxdVH8.defaultProps = {height: 76, width: 1400};

addFonts(FramerDXBkxdVH8, [])